export const useApplyForm = (showSuccessModal, showErrorModal) => {
  return  (data) => {
    fetch(`/share/cover-letters/`, {
      method: 'POST',
      body: data
    }).then((response) => {
      if (response.ok) {
        return response;
      }
      throw new Error('Something went wrong');
    }).then(() => {
      showSuccessModal();
    }).catch((error) => {
      console.log(error)
      showErrorModal();
    });
  };
};