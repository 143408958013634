import routes from "../system/router/routes";
// Code-splitting is automated for `routes` directory
import SystemTemplate from "../templates/System";
import "../styles/main.scss";
import { BrowserRouter } from "react-router-dom";
import classNames from "classnames";
import {useEffect, useState} from "preact/hooks";

const App = () => {
    const [platform, setIsPlatform] = useState('v2');
    useEffect(() => {
        if(navigator?.platform !== 'MacIntel') {
            setIsPlatform('v1')
        }
    }, [navigator])
    return (
        <div className={classNames(`app`, `app-${platform}`)} id="app">
          <BrowserRouter>
            <SystemTemplate>{routes}</SystemTemplate>
          </BrowserRouter>
        </div>
    );
};

export default App;
