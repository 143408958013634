const AdvantagesCard = ({ img, title, description }) => {
  return (
    <div className={"advantages"}>
      <div className={"advantages__wrapper"}>
        <div className={"advantages__image"}>{img}</div>
        <div className={"advantages__info"}>
          <div className={"advantages__info-title"}>{title}</div>
          <div className={"advantages__info-desc"}>{description}</div>
        </div>
      </div>
    </div>
  );
};

export default AdvantagesCard;
