import classNames from "classnames";

const IntroducingInfoCard = ({ className, id, title, text }) => {
  return (
    <div className={"introducing-info-card-item-wrap"}>
      <div
        className={classNames(
          "introducing-info-card-item",
          `introducing-info-card-item__${className}`
        )}
      >
        <div className={"introducing-info-card-item__number"}>{id}</div>
        <div
          className={classNames(
            `introducing-info-card-item__${className}-name`,
            "introducing-info-card-item__name"
          )}
        >
          {title}
        </div>
        <div
          className={classNames(
            `introducing-info-card-item__${className}-progress`,
            "introducing-info-card-item__progress"
          )}
        ></div>
        <div className={"introducing-info-card-item__info"}>{text}</div>
      </div>
    </div>
  );
};

export default IntroducingInfoCard;
