import PositionCard from "./PositionCard";
import classNames from "classnames";

const PositionCardsContainer = ({ data, isPaddingBottom }) => {
  return (
    <div
      className={classNames(
        isPaddingBottom && "positions-card__additional",
        "positions-card"
      )}
    >
      <div className={""}>
        {data?.map((item) => {
          return (
            <PositionCard
              location={item.locations}
              slug={item.slug}
              department={item.department}
              offerName={item.title}
              experience={item.text_short}
              id={item.slug}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PositionCardsContainer;
