import MainTemplate from "../../templates/Main";
import JobOpportunitiesPageTemplate from "./JobOpportunitiesPageTemplate";
import {Helmet} from 'react-helmet'

const JobOpportunitiesPage = () => {
  return (
      <>
        <Helmet>
          <title>Giti2c | Jobs</title>
        </Helmet>
        <MainTemplate>
          <JobOpportunitiesPageTemplate />
        </MainTemplate>
      </>
  );
};

export default JobOpportunitiesPage;
