import classNames from "classnames";
import { useDropzone } from "react-dropzone";
import Input from "../input/Input";
import ArrowUpload from "../../assets/icons/arrow_upload.svg";

const DropZone = ({
  fileValue,
  fileRef,
  className,
  onBlur,
  onChange,
  theme,
  error,
}) => {
  const tmp = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (fileValue) {
        return;
      }
      onChange && onChange(acceptedFiles[0]);
    },
  });

  return (
    <label
      {...tmp.getRootProps({
        className: classNames(`drop-zone`, `drop-zone--${theme}`, className, {
          "has-error": error,
        }),
        onClick: (event) => {event.stopPropagation()}
      })}
      htmlFor={"resume"}
      className={classNames(
        fileValue && "form__info-radio-file",
        "form__info-radio",
        error && `form__info-radio-${error}`
      )}
    >
      <Input
        getInputProps={tmp.getInputProps}
        isDragActive={!fileValue && tmp.isDragAccept}
        fileRef={fileRef}
        icon={<ArrowUpload />}
        iconType={"first"}
        onBlur={onBlur}
        error={error}
        onChange={onChange}
        className={"custom-input__radio"}
        label={"Upload a file"}
        value={fileValue?.name}
        id="resume"
        name="cv"
        type={"file"}
      />
    </label>
  );
};

export default DropZone;
