import LocationIcon from "../../assets/location-icon.svg";
import DepartmentIcon from "../../assets/department-icon.svg";
import Btn from "../btn";
import { useShowModal } from "../../modules/modals/hooks";
import { applyPositionModal } from "../../modules/modals/modals";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useContext, useEffect, useState } from "preact/hooks";
import ModalsContext from "../../modules/user/components/ModalsContext";
import classNames from "classnames";

const PostionCard = ({ id, slug, experience, offerName, department, location }) => {
  const navigate = useNavigate();
  const { show } = useContext(ModalsContext);

  const [isFilled, setIsFilled] = useState(false);
  const showApplyPositionModal = useShowModal(
    applyPositionModal({
      title: offerName,
      slug: slug,
      position: offerName,
      department: department,
      location: location,
      locationIcon: <LocationIcon />,
      departmentIcon: <DepartmentIcon />,
      className: "filters",
    })
  );
  useEffect(() => {
    if (!show) {
      setIsFilled(false);
    }
  }, [show]);
  const showApplyModal = () => {
    showApplyPositionModal();
    setIsFilled(true);
  };
  return (
      <>
    <div
      onClick={() => isMobile && navigate(`/job-opportunities/${id}`)}
      className={classNames("position-card-item", "position-card-item__main")}
    >
      <div className={"position-card-item__head"}>
        <div className={"position-card-item__head-title"}>{offerName}</div>
        {/*<div dangerouslySetInnerHTML={{__html: experience}} className={"position-card-item__head-subtitle"} />*/}
      </div>
      <div className={"position-card-item__filters"}>
        <div
          className={
            "position-card-item__filters-item position-card-item__filters-item-first"
          }
        >
          <div className={"position-card-item__filters-item-image"}>
            <LocationIcon className={"position-card-item__filters-item-icon"} />
          </div>
          <span>{location.map(item => <span className={"position-card-item__filters-item-wrap"}>{item}<span className={"position-card-item__filters-item-wrap-separator"}></span></span>)}</span>
        </div>
        <div
          className={
            "position-card-item__filters-item position-card-item__filters-item-second"
          }
        >
          <div className={"position-card-item__filters-item-image"}>
            <DepartmentIcon
              className={"position-card-item__filters-item-icon"}
            />
          </div>
          <span>{department}</span>
        </div>
      </div>
      <div className={"position-card-item__buttons"}>
        <Btn
          linkTo={`job-opportunities/${id}`}
          label="Read more"
          type="tertiary"
        />
        <Btn
          onClick={showApplyModal}
          label="Apply to position"
          type={"quaternary"}
          isFilled={isFilled}
        />
      </div>
    </div>
        <div
            className={classNames("position-card-item", "position-card-item__additional")}
        >
          <div className={"position-card-item__head"}>
            <div className={"position-card-item__head-title"}>{offerName}</div>
            {/*<div dangerouslySetInnerHTML={{__html: experience}} className={"position-card-item__head-subtitle"} />*/}
          </div>
          <div className={"position-card-item__filters"}>
            <div
                className={
                  "position-card-item__filters-item position-card-item__filters-item-first"
                }
            >
              <div className={"position-card-item__filters-item-image"}>
                <LocationIcon className={"position-card-item__filters-item-icon"} />
              </div>
              <span>{location.map(item => <span className={"position-card-item__filters-item-wrap"}>{item}<span className={"position-card-item__filters-item-wrap-separator"}></span></span>)}</span>
            </div>
            <div
                className={
                  "position-card-item__filters-item position-card-item__filters-item-second"
                }
            >
              <div className={"position-card-item__filters-item-image"}>
                <DepartmentIcon
                    className={"position-card-item__filters-item-icon"}
                />
              </div>
              <span>{department}</span>
            </div>
          </div>
          <div className={"position-card-item__buttons"}>
            <Btn
                linkTo={`job-opportunities/${id}`}
                label="Read more"
                type="tertiary"
            />
            <Btn
                onClick={showApplyModal}
                label="Apply to position"
                type={"quaternary"}
                isFilled={isFilled}
            />
          </div>
        </div>
      </>
  );
};

export default PostionCard;
