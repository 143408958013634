import ApplyPositionModal from "../user/components/modals/ApplyPositionModal";
import ContactUsModal from "../user/components/modals/ContactUsModal";
import SuccessModal from "../user/components/modals/SuccessModal";
import ErrorModal from "../user/components/modals/ErrorModal";

export const applyPositionModal = ({
  title,
  position,
  department,
  slug,
  isContact,
  location,
  locationIcon,
  departmentIcon,
  className,
}) => ({
  component: ApplyPositionModal,
  componentProps: {
    title,
    isContact,
    position,
    slug,
    department,
    location,
    locationIcon,
    departmentIcon,
    className,
  },
});

export const contactUsModal = ({ title, className }) => ({
  component: ContactUsModal,
  componentProps: {
    title,
    className,
  },
});

export const successModal = ({
  title,
  className,
  text,
  subtext,
  position,
  department,
  location,
  locationIcon,
  departmentIcon,
}) => ({
  component: SuccessModal,
  componentProps: {
    title,
    className,
    text,
    subtext,
    position,
    department,
    location,
    locationIcon,
    departmentIcon,
  },
});

export const errorModal = ({
  title,
  className,
  text,
  subtext,
  position,
  department,
  location,
  locationIcon,
  departmentIcon,
}) => ({
  component: ErrorModal,
  componentProps: {
    title,
    className,
    text,
    subtext,
    position,
    department,
    location,
    locationIcon,
    departmentIcon,
  },
});