import Btn from "../btn";
import { useShowModal } from "../../modules/modals/hooks";
import { applyPositionModal } from "../../modules/modals/modals";
import { useContext, useEffect, useState } from "preact/hooks";
import ModalsContext from "../../modules/user/components/ModalsContext";

const Contact = () => {
  const { show } = useContext(ModalsContext);

  const showApplyPositionModal = useShowModal(
    applyPositionModal({
      title: "Tell us about yourself!",
      className: "head",
      isContact: true,
    })
  );
  const [isFilled, setIsFilled] = useState(false);
  useEffect(() => {
    if (!show) {
      setIsFilled(false);
    }
  }, [show]);
  const showModal = () => {
    showApplyPositionModal();
    setIsFilled(true);
  };
  return (
    <div className={"contact"}>
      <div className={"contact__title"}>
        Can't find a vacancy that fits you?
      </div>
      <div className={"contact__subtitle"}>
        Fill in your resume, and we will contact you if we find something
        suitable.
      </div>
      <div>
        <Btn
          onClick={showModal}
          label={"Contact us"}
          type={"quaternary"}
          isFilled={isFilled}
        />
      </div>
    </div>
  );
};

export default Contact;
