export const useFormikHelper = (formik) => {
  const { errors, touched, status, setFieldTouched, setStatus, setFieldValue } =
    formik;
  const error = (name) =>
    (touched?.[name] && errors?.[name]) || (touched?.[name] && status?.[name]);
  const onChange = (name, value) => {
    setFieldValue(name, value);
    setStatus({ ...status, [name]: "" });
  };
  const onBlur = (name) => {
    setFieldTouched(name);
    setStatus({ ...status, [name]: "" });
  };
  return { error, onChange, onBlur };
};
