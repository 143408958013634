import Btn from "../btn";
import { Link } from "react-router-dom";
import { useRef } from "preact/compat";
import classNames from "classnames";
import {Transition} from "react-transition-group";

const LinkItem = ({ item }) => {
  const scrollRef = useRef(null);
  return (
      <Transition in={location.pathname !== item.href} timeout={200}>
        {state => <Link
            ref={scrollRef}
            id={item.id}
            className={classNames(
                location.pathname === item.href && "header__link-active",
                "header__link"
            )}
            to={item.href}
        >
          <Btn className={state} type="primary" label={item.label} />
        </Link>}

      </Transition>
  );
};

export default LinkItem;
