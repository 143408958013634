import IntroducingInfoCardContainer from "../../components/introducingInfoCard/IntroducingInfoCardContainer";
import AboutVariant1 from "../../assets/about/about_variant1.svg";
import AboutVariant2 from "../../assets/about/about_variant2.svg";
import AboutVariant3 from "../../assets/about/about_variant3.svg";
import AboutVariant4 from "../../assets/about/about_variant4.svg";
import {useContext} from "preact/hooks";
import FilterContext from "../../modules/filters/components/FilterContext";
import classNames from "classnames";

const AboutPageTemplate = () => {
  const {platform} = useContext(FilterContext)
  return (
    <div className={"about"}>
      <div className={"about__title"}>
        <div className={classNames(`about__title-wrap`, `about__title-wrap-${platform}`)} />
        <div className={"about__animation"}>
          <div className={"about__animation-variant1"}>
            <AboutVariant1 />
          </div>
          <div className={"about__animation-variant2"}>
            <AboutVariant2 />
          </div>
          <div className={"about__animation-variant3"}>
            <AboutVariant3 />
          </div>
          <div className={"about__animation-variant4"}>
            <AboutVariant4 />
          </div>
        </div>
        <div>We develop solutions for</div>
        <div>
          {" "}
          <span className={"about__title-gradient"}>entertainment</span> products
        </div>
        <div className={"about__subtitle"}>By strong international team</div>
      </div>
      <IntroducingInfoCardContainer />
    </div>
  );
};
export default AboutPageTemplate;
