import MainLogo from "../../assets/Logo.svg";
import Btn from "../btn";
import { useShowModal } from "../../modules/modals/hooks";
import { contactUsModal } from "../../modules/modals/modals";
import { useNavigate } from "react-router-dom";
import LinkItem from "./LinkItem";
import { useContext, useEffect, useState } from "preact/hooks";
import ModalsContext from "../../modules/user/components/ModalsContext";
import {useRef} from "preact/compat";
import FilterContext from "../../modules/filters/components/FilterContext";
import classNames from "classnames";

const Header = () => {
  const { show } = useContext(ModalsContext);
  const { platform } = useContext(FilterContext);
  const navigate = useNavigate();
  const [isFilled, setIsFilled] = useState(false);
  const navRef = useRef(null);
  useEffect(() => {
      if(location.pathname === '/job-opportunities') {
          navRef.current.scrollLeft = 500;
      }
  }, [navRef])
  useEffect(() => {
    if (!show) {
      setIsFilled(false);
    }
  }, [show]);
  const showContactUsModal = useShowModal(
    contactUsModal({
      title: "Contact us",
      className: "head",
    })
  );
  const showModal = () => {
    showContactUsModal();
    setIsFilled(true);
  };
  return (
    <header className={classNames(`header`, `header-${platform}`)}>
      <div onClick={() => {navigate('/introducing')}} className={"header__main-logo"}>
        <MainLogo />
      </div>
      <nav ref={navRef} className={"header__nav"}>
        {[
          { label: "Introducing", href: "/introducing", id: "introducing" },
          { label: "About Giti2c", href: "/about", id: "about" },
          { label: "Job opportunities", href: "/job-opportunities", id: "job" },
        ].map((item) => (
          <LinkItem item={item} />
        ))}
      </nav>
      <div className={"header__btn"}>
        <Btn
          onClick={showModal}
          type="secondary"
          label={"Contact us"}
          isFilled={isFilled}
        />
      </div>
    </header>
  );
};

export default Header;
