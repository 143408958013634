import IntroducingInfoCard from "./IntroducingInfoCard";

const IntroducingInfoCardContainer = () => {
  const arr = [
    {
      className: "first",
      id: "01",
      title: "Customer Support",
      text: "We provide expertise in creating from scratch complex Customer Service departments for all kinds of markets according to the business needs.",
    },
    {
      className: "second",
      id: "02",
      title: "Marketing",
      text: "Using innovative technologies and the latest marketing solutions, we help our clients meet their business goals and achieve measurable results.",
    },
    {
      className: "third",
      id: "03",
      title: "Development",
      text: "Using up-to-date modern technologies we develop and provide product solutions of any complexity for the online business.",
    },
    {
      className: "fourth",
      id: "04",
      title: "Analyst",
      text: "We use intricate techniques beyond traditional data analysis. With our deep expertise in entertainment products, we provide complex machine learning solutions, risk & statistical analysis for specific business needs.",
    },
  ];
  return (
    <div className={"introducing-info-card"}>
      <div className={"introducing-info-card__container"}>
        {arr.map((item) => (
          <IntroducingInfoCard {...item} />
        ))}
      </div>
    </div>
  );
};

export default IntroducingInfoCardContainer;
