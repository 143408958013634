import mainAnimation from "../../assets/3dIntroducing.mp4";
import {useContext} from "preact/hooks";
import FilterContext from "../../modules/filters/components/FilterContext";
import classNames from "classnames";

const IntroducingPageTemplate = () => {
    const {platform, platformVideo} = useContext(FilterContext)
    return (
        <div className={"introducing"} style={{ transition: "2s" }}>
          <div className={classNames(`introducing__container`, `introducing__container-${platform}`)} />
          <video className={classNames("introducing__video", `introducing__video-${platformVideo}`)} muted autoPlay loop playsInline>
            <source src={mainAnimation} type="video/mp4" />
          </video>
          <div className={"introducing__title"}>
            <div>Become a part of</div>
            <div className={"introducing__title-gradient"}>
                a team of the most talented
            </div>
            <div>
              <span className={"introducing__title-additional"}>
                professionals{" "}
              </span>
              in the industry
            </div>
            <div className={"introducing__subtitle"}>
                Start exploring the site by scrolling down
            </div>
          </div>
        </div>
    );
};
export default IntroducingPageTemplate;
