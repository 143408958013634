export const useFilters = (
  initialData,
  filteredData,
  isFiltered,
  setFilteredData,
  setIsFiltered
) => {
  return (data, section, anotherData, anotherSection) => {
    let newFilteredData = [];
    const isSomeFilterData = Object.keys(data).some((item) => data[item]);
    const isSomeFilterAnotherData = Object.keys(anotherData).some(
      (item) => anotherData[item]
    );
    if (!isFiltered[anotherSection]) {
      newFilteredData = initialData.filter((item) => {
        if(section === 'department') {
          return data[item[section]]
        }
        else {
          return item[section].find(country => data[country])
        }
      });
      setIsFiltered({ ...isFiltered, [section]: isSomeFilterData });
    }
    if (isFiltered[anotherSection]) {
      if (!isSomeFilterData) {
        newFilteredData = initialData.filter((item) => {
          if(anotherSection === 'department') {
            return anotherData[item[anotherSection]]
          }
          else {
            return item[anotherSection].find(country => anotherData[country])
          }
        });
        setIsFiltered({ ...isFiltered, [section]: false });
      } else {
        newFilteredData = initialData.filter(
            (item) => {
              if(section === 'department') {
                return data[item[section]] && item[anotherSection].find(country => anotherData[country])
              }
              else {
                return item[section].find(country => data[country]) && anotherData[item[anotherSection]]
              }
            }
        );
        setIsFiltered({ ...isFiltered, [section]: true });
      }
    }

    if (!isSomeFilterAnotherData && !isSomeFilterData) {
      setFilteredData(initialData);
    } else {
      setFilteredData(newFilteredData);
    }
  };
};
