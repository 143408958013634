import MainTemplate from "../../templates/Main";
import HomePageTemplate from "./HomePageTemplate";

const HomePage = () => {
  return (
    <MainTemplate>
      <HomePageTemplate />
    </MainTemplate>
  );
};

export default HomePage;
