import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
const Contact = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={"dots"}>
      <div
        onClick={() => navigate("/introducing")}
        className={classNames(
          location.pathname === "/introducing" && "dots__item-active",
          "dots__item"
        )}
      />
      <div
        onClick={() => navigate("/about")}
        className={classNames(
          location.pathname === "/about" && "dots__item-active",
          "dots__item"
        )}
      />
      <div
        onClick={() => navigate("/job-opportunities")}
        className={classNames(
          location.pathname === "/job-opportunities" && "dots__item-active",
          "dots__item"
        )}
      />
    </div>
  );
};

export default Contact;
