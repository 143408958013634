import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import Spinner from '../spinner/index';

const Btn = ({ label, type, className, linkTo, onClick, isFilled, isLoading }) => {
  const navigate = useNavigate();
  const clickHandler = (e) => {
    if (linkTo) {
      navigate(`/${linkTo}`);
    }
    onClick && onClick();
  };

  return (
    <button
        disabled={isLoading}
      onClick={clickHandler}
      className={classNames(
        isFilled && `btn__${type}-active`,
        "btn",
        `btn__${type}`,
          isLoading && `btn__${type}-disabled`,
        className
      )}
    >
      {isLoading && <Spinner />}
      <span className={classNames(`btn__label`, `btn__${type}-label`, isLoading && `btn__${type}-label-disabled`)}>{label}</span>
      <div className={classNames(`btn__over btn__${type}-over`,
          isLoading && `btn__${type}-over-disabled`)} />
      <div
        className={classNames(
          isFilled && `btn__${type}-over-wrap-active`,
          `btn__${type}-over-wrap`,
            isLoading && `btn__${type}-over-wrap-disabled`)}
      />
      <div
        className={classNames(
          isFilled && `btn__${type}-over-wrap-third-active`,
          `btn__${type}-over-wrap-third`,
            isLoading && `btn__${type}-over-wrap-third-disabled`
        )}
      />
    </button>
  );
};

export default Btn;
