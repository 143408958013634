import MainTemplate from "../../templates/Main";
import IntroducingPageTemplate from "./IntroducingPageTemplate";
import {Helmet} from "react-helmet";

const IntroducingPage = () => {
  return (
      <>
        <Helmet>
            <title>Giti2c</title>
        </Helmet>
        <MainTemplate>
          <IntroducingPageTemplate />
        </MainTemplate>
      </>
  );
};

export default IntroducingPage;
