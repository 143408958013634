import classNames from "classnames";
import ArrowDown from "../../assets/icons/arrow-down.svg";
import { useState } from "preact/hooks";
import Filter from "./Filter";

const FiltersItem = ({
  data,
  onClick,
  setFilterInitial,
  setFilters,
  icon,
  setIsOpenGlobal,
  anotherSection,
  anotherData,
  section,
  title,
  filterInitial,
  isFiltered,
  makeFilter,
}) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const hideFilterOnClose = () => {
    setIsOpenGlobal(false);
    setIsOpenMenu(false);
    setFilters(filterInitial);
  };
  const hideFilters = () => {
    setIsOpenGlobal(false);
    setIsOpenMenu(false);
  };
  const openMenu = () => {
    setIsOpenGlobal(true);
    setIsOpenMenu(true);
  };
  const getFilterAmount = (data) => {
    let amount = 0;
    Object.keys(data).forEach((item) => {
      if (data[item]) {
        amount++;
      }
    });
    return amount;
  };
  const amount = getFilterAmount(filterInitial);
  return (
    <>
      <div onClick={openMenu} className={"job-opportunities__head-filter-wrap"}>
        {icon}
        <div
          className={classNames(
            "job-opportunities__head-filter-item",
            isOpenMenu && "job-opportunities__head-filter-item-opened"
          )}
        >
          {title}
        </div>
        {isFiltered && !!amount && (
          <div className={"job-opportunities__head-filter-item-amount-wrap"}>
            (
            <span
              className={classNames(
                isOpenMenu &&
                  "job-opportunities__head-filter-item-amount-opened",
                "job-opportunities__head-filter-item-amount"
              )}
            >
              {amount}
            </span>
            )
          </div>
        )}
        <div
          className={classNames(
            isOpenMenu && "job-opportunities__head-filter-item-arrow-opened",
            "job-opportunities__head-filter-item-arrow"
          )}
        >
          {" "}
          <ArrowDown />
        </div>
      </div>
      {isOpenMenu && (
        <Filter
          makeFilter={makeFilter}
          hideFilters={hideFilters}
          hideFiltersOnClose={hideFilterOnClose}
          setFilterInitial={setFilterInitial}
          title={title}
          onClick={onClick}
          data={data}
          section={section}
          anotherSection={anotherSection}
          anotherData={anotherData}
        />
      )}
    </>
  );
};

export default FiltersItem;
