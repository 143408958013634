import JobOpportunitiesInfoPageTemplate from "./JobOpportunitiesInfoPageTemplate";
import SecondaryTemplate from "../../templates/Secondary";
import { useParams } from "react-router";
import {Helmet} from "react-helmet";

const JobOpportunitiesInfoPage = () => {
  const params = useParams();
  const jobId = params.id;

  return (
      <>
        <Helmet>
          <title>Giti2c | Jobs</title>
        </Helmet>
        <SecondaryTemplate>
          <JobOpportunitiesInfoPageTemplate id={jobId} />
        </SecondaryTemplate>
      </>
  );
};

export default JobOpportunitiesInfoPage;
