import Header from "../../components/header";
import Footer from "../../components/footer";
import Dots from "../../components/dots/Dots";
import { useContext, useEffect } from "preact/hooks";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import ModalsContext from "../../modules/user/components/ModalsContext";
import classNames from "classnames";
import FilterContext from "../../modules/filters/components/FilterContext";

const MainTemplate = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { tmp, setTmp } = useContext(ModalsContext);

  useEffect(() => {
    setTimeout(() => {
      setTmp(location.pathname);
    }, 1000);
  }, [location.pathname]);

  const routes = ["/introducing", "/about", "/job-opportunities"];

  const handleScrollTop = (event) => {
    if (tmp === location.pathname) {
      const index = routes.findIndex((item) => item === location.pathname);
      if (event.currentTarget.scrollTop === 0 && routes[index - 1]) {
        navigate(routes[index - 1]);
      }
    }
  };

  const handleScrollBottom = (event) => {
    if (tmp === location.pathname) {
      const index = routes.findIndex((item) => item === location.pathname);
      let bottom =
        event.currentTarget.scrollHeight -
          event.currentTarget.scrollTop -
          0.5 ===
          event.currentTarget.clientHeight ||
        event.currentTarget.scrollHeight - event.currentTarget.scrollTop ===
          event.currentTarget.clientHeight;
      if (bottom && routes[index + 1]) {
        navigate(routes[index + 1]);
      }
    }
  };

  const { isOpenGlobal, platform } = useContext(FilterContext);

  return (
    <ReactScrollWheelHandler
      timeout="1000"
      upHandler={(e) => !isOpenGlobal && handleScrollTop(e)}
      downHandler={(e) => handleScrollBottom(e)}
      className={classNames(location.pathname === '/introducing' && "wrapper__introducing",isOpenGlobal && "wrapper__scrollable", "wrapper")}
    >
      <Header />
      <Dots />
      <div
        className={classNames(
          location.pathname === "/introducing" && "wrapper__item-introducing",
          "wrapper__item"
        )}
      >
        {children}
      </div>
      <Footer />
    </ReactScrollWheelHandler>
  );
};

export default MainTemplate;
