import { useState } from "preact/hooks";
import { createContext } from "preact";
import { useLocation } from "react-router-dom";

const Context = createContext(null);

const ModalsContextProvider = ({ children }) => {
  const location = useLocation();
  const [show, showModal] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const [tmp, setTmp] = useState(location.pathname);

  return (
    <Context.Provider
      value={{
        show,
        showModal,
        modalProps,
        setModalProps,
        tmp,
        setTmp,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
export { ModalsContextProvider };
