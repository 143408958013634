import BureaucracyIcon from "../../assets/icons/advantages/advantages_bureaucracy_icon.svg";
import CalendarIcon from "../../assets/icons/advantages/advantages_calendar_icon.svg";
import EducationIcon from "../../assets/icons/advantages/advantages_education_icon.svg";
import ProjectsIcon from "../../assets/icons/advantages/advantages_projects_icon.svg";
import SCIcon from "../../assets/icons/advantages/advantages_sc_icon.svg";
import TalentIcon from "../../assets/icons/advantages/advantages_talent_icon.svg";

export const advantagesData = [
  {
    img: <CalendarIcon />,
    title: "Unlimited vacation and sick leave policy",
    description: "Flexible working schedule",
  },
  {
    img: <BureaucracyIcon />,
    title: "Zero bureaucracy",
    description:
      "Support from colleagues and automatization of time-consuming processes",
  },
  {
    img: <EducationIcon />,
    title: "Educational Allowance",
    description:
      "Opportunity to expand your knowledge and experience, not only in your work but also in other areas",
  },
  {
    img: <TalentIcon />,
    title: "Top talent - top salaries",
    description:
      "International team focused on excellence in product development",
  },
  {
    img: <ProjectsIcon />,
    title: "Challenging projects and tasks",
    description: "Too good to pass up, right?",
  },
  {
    img: <SCIcon />,
    title: "Medical Insurance",
    description: "Find the right medical scheme for you and get compensated for it",
  },
];
