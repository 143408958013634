import Close from "../../assets/icons/close.svg";

const Modal = ({
  isOpen,
  hideModal,
  componentProps,
  component: CustomComponent,
}) => {
  return (
    <>
      {isOpen && (
        <div className={"modal-wrap"}>
          <div className={"modal"}>
            <div onClick={hideModal} className={"modal__close"}>
              <Close />
            </div>
            <div className={`modal__${componentProps.className}`}>
              <div className={"modal__title"}>{componentProps.title}</div>
              <div className={`modal__item-${componentProps.className}`}>
                <div className={`modal__item-${componentProps.className}-img`}>
                  {componentProps.departmentIcon}
                </div>
                <div>
                  <div
                    className={`modal__item-${componentProps.className}-title`}
                  >
                    {componentProps.department}
                  </div>
                </div>
                <div
                  className={`modal__item-${componentProps.className}-separator`}
                />
                <div className={`modal__item-${componentProps.className}`}>
                  <div
                    className={`modal__item-${componentProps.className}-img`}
                  >
                    {componentProps.locationIcon}
                  </div>
                </div>
                <div
                  className={`modal__item-${componentProps.className}-title`}
                >
                  {componentProps.location?.join(' / ')}
                </div>
              </div>
            </div>
            <CustomComponent
              hideModal={hideModal}
              location={componentProps.location}
              department={componentProps.department}
              position={componentProps.position}
              offerName={componentProps.offerName}
              slug={componentProps.slug}
              title={componentProps.title}
              text={componentProps.text}
              isContact={componentProps.isContact}
              subtext={componentProps.subtext}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
