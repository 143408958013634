const TextArea = ({ onChange, targetVal, placeholder }) => {
  const onChangeHandler = (e) => {
    const value = targetVal ? e.target.value : e;
    onChange && onChange(value);
  };

  return (
    <div className={"custom-textarea"}>
      <textarea
        placeholder={placeholder}
        onChange={onChangeHandler}
        name=""
        id=""
        cols="30"
        rows="10"
      ></textarea>
    </div>
  );
};

export default TextArea;
