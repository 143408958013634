import Btn from "../../../../components/btn";

const SuccessModal = ({ hideModal, text, subtext }) => {
  return (
    <>
      <div className={"modal__success"}>
        <svg className="cross__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle className="cross__circle" cx="26" cy="26" r="25" fill="none"/>
          <path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20"/>
          <path className="cross__path cross__path--right" fill="none" d="M16,36 l20,-20"/>
        </svg>
        <div className={"modal__success-text"}>{text}</div>
        <div className={"modal__success-subtext"}>{subtext}</div>
        <div className={"modal__success-btn"}>
          <Btn
            onClick={hideModal}
            type={"secondary"}
            label={"Back to website"}
          />
        </div>
      </div>
    </>
  );
};

export default SuccessModal;
