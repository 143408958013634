import JobOpportunitiesInfoContainer from "../../components/jobOpportunitiesInfo/JobOpportunitiesInfoContainer";

const JobOpportunitiesInfoPageTemplate = ({ id }) => {
  return (
    <div>
      <JobOpportunitiesInfoContainer id={id} />
    </div>
  );
};
export default JobOpportunitiesInfoPageTemplate;
