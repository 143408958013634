import Input from "../../../../components/input/Input";
import TextArea from "../../../../components/textArea/TextArea";
import Btn from "../../../../components/btn";
import { useFormik } from "formik";
import { ApplyPositionSchema } from "./constants";
import { useFormikHelper } from "../../../../system/helpers/formikHelperHook";
import { useRef } from "preact/compat";
import DropZone from "../../../../components/dropZone/DropZone";

const ApplyPositionForm = ({ onSubmit, isSpinner }) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      email: "",
      phone: "",
      slug: "",
      file: "",
      letter: "",
      position: "",
      department: "",
      location: "",
    },
    validationSchema: ApplyPositionSchema(),
    onSubmit: onSubmit,
  });

  const fileRef = useRef(null);
  const { error, onChange, onBlur } = useFormikHelper(formik);

  return (
    <div className={"form"}>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className={"form__title"}>Personal information</div>
          <div className={"form__info"}>
            <Input
              placeholder={"First name"}
              targetVal
              value={formik.values.name}
              name="name"
              onChange={(val) => onChange("name", val)}
              onBlur={() => onBlur("name")}
              error={error("name")}
            />
            <Input
              placeholder={"Last name"}
              targetVal
              value={formik.values.surname}
              name="surname"
              onChange={(val) => onChange("surname", val)}
              onBlur={() => onBlur("surname")}
              error={error("surname")}
            />
            <Input
              placeholder={"Email Address"}
              targetVal
              value={formik.values.email}
              name="email"
              onChange={(val) => onChange("email", val)}
              onBlur={() => onBlur("email")}
              error={error("email")}
            />
            <Input
              placeholder={"Mobile Phone"}
              targetVal
              type="tel"
              name="phone"
              value={formik.values.phone}
              onChange={(val) => onChange("phone", val.replace(/[^\d]/g, ""))}
              onBlur={() => onBlur("phone")}
              error={error("phone")}
            />
            <Input
              placeholder={"Location"}
              targetVal
              value={formik.values.location}
              name="location"
              onChange={(val) => onChange("location", val)}
              onBlur={() => onBlur("location")}
              error={error("location")}
            />
          </div>
        </div>
        <div>
          <div className={"form__title"}>CV</div>
          <div className={"form__info-cv"}>
            <DropZone
              onChange={(val) => onChange("file", val)}
              onBlur={() => onBlur("file")}
              error={error("file")}
              fileRef={fileRef}
              fileValue={formik.values.file}
            />
          </div>
        </div>
        <div className={"form__letter"}>
          <div className={"form__title form__title-letter"}>Cover letter</div>
          <TextArea
            placeholder={"Is there something we should know about? (optional)"}
            targetVal
            value={formik.values.letter}
            name="letter"
            error={error("letter")}
            onChange={(val) => onChange("letter", val)}
          />
        </div>
        <div className={"form__btn"}>
          <Btn type={"secondary"} label={"Apply"} isLoading={isSpinner} />
        </div>
      </form>
    </div>
  );
};

export default ApplyPositionForm;
