import ContactUsForm from "../forms/ContactUsForm";
import {useApplyForm} from "../../apiHooks";
import { useShowModal } from "../../../modals/hooks";
import {errorModal, successModal} from "../../../modals/modals";
import {useState} from "preact/hooks";

const ContactUsModal = () => {
    const [isSpinner, setSpinner] = useState(false);
  const showSuccessModal = useShowModal(
    successModal({
      title: "Contact us",
      className: "head",
      text: "Thanks for contacting us!",
      subtext: "We will reply you soon",
    })
  );
  const showErrorModal = useShowModal(
    errorModal({
        title: "Contact us",
        className: "head",
        text: "Something went wrong!",
        subtext: "Please, try again",
    })
);
  const contactHandler = useApplyForm(showSuccessModal, showErrorModal);
  const onSubmit = (values) => {
    setSpinner(true);
    const formData = new FormData();
    formData.append("first_name", values.name);
    formData.append("last_name", values.surname);
    formData.append("email", values.email);
    formData.append("location", values.location);
    if (values.letter) {
      formData.append("text", values.letter);
    }
    contactHandler(formData);
  };
  return (
    <>
      <ContactUsForm onSubmit={onSubmit} isSpinner={isSpinner} />
    </>
  );
};

export default ContactUsModal;
