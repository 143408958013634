import SocialLogo from "../../assets/social_linkedin.svg";

const Footer = () => (
  <footer className={"footer"}>
    <div className="footer__label">Giti2c 2024</div>
    <a href={"https://www.linkedin.com/company/giti2c/about"} target="_blank" rel="noreferrer" className={"footer__wrap-social"}>
      <SocialLogo className={"footer__social"} />
    </a>
    <div className="footer__label footer__label-second">Keep in touch!</div>
  </footer>
);

export default Footer;
