import FilterForm from "./FilterForm";
import Btn from "../btn";

const Filter = ({
  data,
  title,
  hideFilters,
  onClick,
  hideFiltersOnClose,
  makeFilter,
  section,
  anotherData,
  anotherSection,
  setFilterInitial,
}) => {
  const filterHandler = () => {
    makeFilter(data, section, anotherData, anotherSection);
    setFilterInitial(data);
    hideFilters();
  };
  return (
    <>
      <div onClick={hideFiltersOnClose} className={"filter-wrap"} />
      <div className={"filter"}>
        <div className={"filter__title"}>Select {title}</div>
        <FilterForm onClick={onClick} data={data} />
        <Btn
          onClick={filterHandler}
          className={"filter__btn"}
          label={"Apply"}
          type={"secondary"}
        />
      </div>
    </>
  );
};

export default Filter;
