import { useCallback, useContext } from "preact/hooks";
import ModalsContext from "../user/components/ModalsContext";

export const useShowModal = (props) => {
  const { showModal, setModalProps } = useContext(ModalsContext);
  return useCallback(() => {
    showModal(true);
    setModalProps(props);
  }, [props]);
};
