import { advantagesData } from "./constants";
import AdvantagesCard from "./AdvantagesCard";

const AdvantagesCardContainer = () => {
  return (
    <div className={"advantages-wrap"}>
      {advantagesData.map((item) => {
        return <AdvantagesCard {...item} />;
      })}
    </div>
  );
};

export default AdvantagesCardContainer;
