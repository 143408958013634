import * as Yup from "yup";
import {
    isValidEmail, isValidLocation,
    isValidName,
    isValidPhone,
    isValidSurname,
} from "../../../../system/helpers/validationHelper";

const KYC_FILE_SIZE = 10000 * 1024;
const KYC_SUPPORTED_FORMATS = [".pdf", ".doc", ".docx", ".rtf", ".jpg", ".jpeg", ".png", "gif", ".svg"];

const isValidKycFile = () =>
    Yup.mixed()
        .test("fileFormat", "Unsupported format", (value) =>
            value ? KYC_SUPPORTED_FORMATS.includes(value.name.match(/\.([^.]*)$/)[0].toLowerCase()) : true
        )
        .test("fileSize", "Maximum file size 10 MB", (value) =>
            value ? value.size <= KYC_FILE_SIZE : true
        );

export const ContactUsSchema = () =>
  Yup.object().shape({
    name: isValidName().required("Name is required"),
    surname: isValidSurname().required("Surname is required"),
    location: isValidLocation().required("Location is required"),
    email: isValidEmail().required("Email is required"),
    letter: Yup.string().notRequired(),
  });

export const ApplyPositionSchema = () =>
  Yup.object().shape({
    name: isValidName().required("Name is required"),
    surname: isValidSurname().required("Surname is required"),
    phone: isValidPhone().required("Phone is required"),
    email: isValidEmail().required("Email is required"),
    location: isValidLocation().required("Location is required"),
    file: isValidKycFile().required("CV is required"),
    letter: Yup.string().notRequired(),
  });
