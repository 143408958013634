import { useState } from "preact/hooks";
import classNames from "classnames";
import CrossIcon from "../../assets/icons/cross.svg";

const Input = ({
  onChange,
  className,
  name,
  icon,
  getInputProps,
  isDragActive,
  id,
  label,
  error,
  fileRef,
  value,
  iconType,
  targetVal,
  onBlur,
  type,
  placeholder,
}) => {
  const [inputFocus, setInputFocus] = useState(false);
  const onChangeHandler = (e) => {
    if (type === "file") {
      onChange && onChange(fileRef?.current?.files[0]);
      return;
    }
    const value = targetVal ? e.target.value : e;
    onChange && onChange(value);
  };
  const onBlurHandler = () => {
    setInputFocus(false);
    onBlur && onBlur();
  };

  const onRemoveFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    fileRef.current.value = null;
    onChange && onChange("");
  };

  const onStopPropagation = (event) => {
    event.stopImmediatePropagation();
  }

  return (
    <>
      <div
        className={classNames(
          className,
          {
            ["custom-input__has-error"]: error,
            ["custom-input__has-error-file"]: error && type === "file",
          },
          "custom-input",
          isDragActive && `${className}-drag-active`
        )}
      >
        {label && (
          <div className={`${className}-container`}>
            <div className={`${className}-label`}>
              {value ? value : label}
              {value ? (
                <div
                  onClick={onRemoveFile}
                  className={`${className}-label-img`}
                >
                  <CrossIcon />
                </div>
              ) : null}
            </div>
            <div className={`${className}-icon ${className}-icon-${iconType}`}>
              {icon && icon}
            </div>
          </div>
        )}
        <input
          {...getInputProps}
          ref={fileRef}
          value={value}
          type={type}
          id={id}
          name={name}
          onClick={onStopPropagation}
          onBlur={onBlurHandler}
          placeholder={placeholder}
          onChange={onChangeHandler}
        />
        {type !== "file" && error && (
          <span className={classNames("custom-input__error")}>{error}</span>
        )}
      </div>
      {type === "file" && error && (
        <span
          className={classNames("custom-input__error custom-input__error-file")}
        >
          {error}
        </span>
      )}
    </>
  );
};

export default Input;
