import { FilterContextProvider } from "../../modules/filters/components/FilterContext";
import { ModalsContextProvider } from "../../modules/user/components/ModalsContext";
import ModalContainer from "../../modules/modals/components/ModalContainer";

const SystemTemplate = ({ children }) => {
  return (
    <ModalsContextProvider>
      <FilterContextProvider>{children}</FilterContextProvider>
      <ModalContainer />
    </ModalsContextProvider>
  );
};

export default SystemTemplate;
