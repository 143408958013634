import Modal from "../../../components/modal/Modal";
import { useContext } from "preact/hooks";
import ModalsContext from "../../user/components/ModalsContext";

const ModalContainer = () => {
  const { show, modalProps, showModal } = useContext(ModalsContext);
  const hideModal = () => {
    showModal(false);
  };
  return (
    <Modal
      isOpen={show}
      hideModal={hideModal}
      componentProps={modalProps.componentProps}
      component={modalProps.component}
    />
  );
};

export default ModalContainer;
