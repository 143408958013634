import Header from "../../components/header";
import Footer from "../../components/footer";

const SecondaryTemplate = ({ children }) => {
  return (
    <div className={"wrapper-secondary"}>
      <Header />
      <div className={"wrapper-secondary__item"}>{children}</div>
      <Footer />
    </div>
  );
};

export default SecondaryTemplate;
