import ApplyPositionForm from "../forms/ApplyPositionForm";
import {useApplyForm} from "../../apiHooks";
import { useShowModal } from "../../../modals/hooks";
import {errorModal, successModal} from "../../../modals/modals";
import LocationIcon from "../../../../assets/location-icon.svg";
import DepartmentIcon from "../../../../assets/department-icon.svg";
import {useState} from "preact/hooks";

const ApplyPositionModal = ({
  title,
  slug,
  isContact,
  department,
  location,
}) => {
    const [isSpinner, setSpinner] = useState(false);
    const successData = !isContact
    ? {
        title: title,
        className: "filters",
        text: "Thanks for applying to this position!",
        subtext: "We will reply you soon",
        department: department,
        location: location,
        locationIcon: <LocationIcon />,
        departmentIcon: <DepartmentIcon />,
      }
    : {
        title: title,
        className: "head",
        text: "Thanks for contacting us!",
        subtext: "We will reply you soon",
      };
  const errorData = !isContact
      ? {
          title: title,
          className: "filters",
          text: "Something went wrong!",
          subtext: "Please, try again",
          department: department,
          location: location,
          locationIcon: <LocationIcon />,
          departmentIcon: <DepartmentIcon />,
      }
      : {
          title: title,
          className: "head",
          text: "Something went wrong!",
          subtext: "Please, try again",
      };
  const showSuccessModal = useShowModal(successModal(successData));
  const showErrorModal = useShowModal(errorModal(errorData));
  const applyPositionHandler = useApplyForm(showSuccessModal, showErrorModal);
  const onSubmit = (values) => {
    setSpinner(true);
    const formData = new FormData();
    formData.append("first_name", values.name);
    formData.append("last_name", values.surname);
    formData.append("email", values.email);
    formData.append("location", values.location);
    if(values.phone) {
        formData.append("phone", values.phone);
    }
    formData.append("cv", values.file);
    if (values.letter) {
      formData.append("text", values.letter);
    }
    if (slug) {
      formData.append("vacancy_slug", slug);
    }
    applyPositionHandler(formData);
  };
  return (
    <>
      <div />
      <ApplyPositionForm isSpinner={isSpinner} onSubmit={onSubmit} />
    </>
  );
};

export default ApplyPositionModal;
