const FilterForm = ({ data, onClick }) => {
  const dataList = Object.keys(data);
  return (
    <div className={"filter__form"}>
      {dataList.map((item) => {
        return (
          <div onClick={() => onClick(item)} className={"filter__form-item"}>
            <label className={"filter__form-label"}>
              <input
                type="checkbox"
                checked={data[item]}
              />
              {item}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default FilterForm;
