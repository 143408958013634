import PositionCardsContainer from "../../components/positionCard/PositionCardsContainer";
import LocationIcon from "../../assets/location-icon.svg";
import DepartmentIcon from "../../assets/department-icon.svg";
import Contact from "../../components/contact/Contact";
import AdvantagesCardContainer from "../../components/advantages/AdvantagesCardContainer";
import { useContext } from "preact/hooks";
import FiltersItem from "../../components/filters/FiltersItem";
import FilterContext from "../../modules/filters/components/FilterContext";
import { useFilters } from "../../modules/filters/hooks";
import Btn from "../../components/btn";
import {Transition} from "react-transition-group";
import {useEffect} from "preact/compat";
import classNames from "classnames";

const JobOpportunitiesPageTemplate = () => {
  const {
    positionsCardData,
    filteredData,
    isFiltered,
    setFilteredData,
    setIsFiltered,
    setFiltersDepartment,
    filtersDepartment,
    setFiltersLocation,
    filtersLocation,
    setFiltersInitialDepartment,
    setFiltersInitialLocation,
    filtersInitialDepartment,
    filtersInitialLocation,
    setJobOpportunitiesData,
    firstFiltersDepartment,
    firstFiltersLocation,
    setIsOpenGlobal,
    platform
  } = useContext(FilterContext);
  const requestOptions = {
    method: 'GET'
  };

  useEffect(() => {
    fetch("/share/vacancies", requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Something went wrong');
        }).then((responseJson) => {
          setJobOpportunitiesData(responseJson)
        }).catch((error) => {
          console.log(error)
        });
  }, [])

  const makeFilter = useFilters(
    positionsCardData,
    filteredData,
    isFiltered,
    setFilteredData,
    setIsFiltered
  );

  const toggleDepartmentFilters = (filterItem) => {
    const newFilters = {
      ...filtersDepartment,
    };
    newFilters[filterItem] = !newFilters[filterItem];
    setFiltersDepartment(newFilters);
  };

  const toggleLocationFilters = (filterItem) => {
    const newFilters = {
      ...filtersLocation,
    };
    newFilters[filterItem] = !newFilters[filterItem];
    setFiltersLocation(newFilters);
  };

  const removeFilters = () => {
    setFilteredData(positionsCardData);
    setFiltersDepartment(firstFiltersDepartment);
    setFiltersLocation(firstFiltersLocation);
    setFiltersInitialDepartment(firstFiltersDepartment);
    setFiltersInitialLocation(firstFiltersLocation);
    setIsFiltered({ locations: false, department: false });
  };

  return (
    <div className={"job-opportunities"}>
      <div className={classNames(`job-opportunities__container`, `job-opportunities__container-${platform}`)} />
      <div>
        <div className={"job-opportunities__head"}>
          <div className={"job-opportunities__head-title"}>
            Open positions
          </div>
          <div className={"job-opportunities__head-filter"}>
            <Transition in={isFiltered.department || isFiltered.locations}
                        timeout={500}>
              {
                state =>  <div className={`job-opportunities__head-filter-left ${state}`}>
                  <FiltersItem
                      setIsOpenGlobal={setIsOpenGlobal}
                      makeFilter={makeFilter}
                      onClick={toggleLocationFilters}
                      setFilterInitial={setFiltersInitialLocation}
                      filterInitial={filtersInitialLocation}
                      setFilters={setFiltersLocation}
                      data={filtersLocation}
                      anotherData={filtersDepartment}
                      icon={<LocationIcon />}
                      section={"locations"}
                      anotherSection={"department"}
                      title={"Location"}
                      isFiltered={isFiltered.locations}
                  />
                  <FiltersItem
                      setIsOpenGlobal={setIsOpenGlobal}
                      makeFilter={makeFilter}
                      onClick={toggleDepartmentFilters}
                      setFilterInitial={setFiltersInitialDepartment}
                      filterInitial={filtersInitialDepartment}
                      setFilters={setFiltersDepartment}
                      data={filtersDepartment}
                      anotherData={filtersLocation}
                      icon={<DepartmentIcon />}
                      section={"department"}
                      anotherSection={"locations"}
                      title={"Department"}
                      isFiltered={isFiltered.department}
                  />
                </div>
              }
            </Transition>
            <Transition
            in={isFiltered.department || isFiltered.locations}
            timeout={500}
            unmountOnExit>
              {state => <div className={`job-opportunities__head-filter-btn ${state}`}>
                <Btn
                    onClick={removeFilters}
                    type={"secondary"}
                    label={"Remove filters"}
                    className={state}
                />
              </div>}

            </Transition>
          </div>
        </div>
        <div>
          <PositionCardsContainer
            isPaddingBottom={isFiltered.department || isFiltered.locations}
            data={filteredData}
          />
          {(isFiltered.department || isFiltered.locations) && (
            <div className={"job-opportunities__head-filter-btn-mobile"}>
              <Btn
                onClick={removeFilters}
                type={"secondary"}
                label={"Remove filters"}
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <div className={"job-opportunities__head"}>
          <div className={"job-opportunities__head-title"}>
            We are always looking for talents. Do not hesitate to contact us!
          </div>
        </div>
        <Contact />
      </div>
      <div>
        <div className={"job-opportunities__head"}>
          <div className={"job-opportunities__head-title"}>
            Giti2c Advantages
          </div>
        </div>
        <AdvantagesCardContainer />
      </div>
    </div>
  );
};
export default JobOpportunitiesPageTemplate;
