import {useEffect, useState} from "preact/hooks";
import { createContext } from "preact";

const Context = createContext(null);

const FilterContextProvider = ({ children }) => {

    const platformToVersion = {
        MacIntel: "v2"
    }
    const platformToVideoVersion = {
        Win32: "v3"
    }
    const [platform, setIsPlatform] = useState(null)
    const [platformVideo, setIsPlatformToVideo] = useState(null)
    const department = {};
    const locations = {};
    const [jobOpportunitiesData, setJobOpportunitiesData] = useState(null);
    useEffect(() => {
        setIsPlatform(platformToVersion[navigator?.platform] || 'v1');
        setIsPlatformToVideo(platformToVideoVersion[navigator?.platform] || '')
    }, [navigator])
    useEffect(() => {
        setFilteredData(jobOpportunitiesData);
        jobOpportunitiesData?.forEach((item) => {
            department[item.department] = false;
            item.locations.forEach(country => {
                locations[country] = false;
            })
        });
        setFiltersDepartment(department)
        setFiltersLocation(locations)
        setFirstFiltersDepartment(department)
        setFirstFiltersLocation(locations);
        setFiltersInitialDepartment(department);
        setFiltersInitialLocation(locations);
    }, [jobOpportunitiesData])
  const [filteredData, setFilteredData] = useState(null);
  const [isOpenGlobal, setIsOpenGlobal] = useState(false);
  const [filtersDepartment, setFiltersDepartment] = useState(null);
  const [firstFiltersDepartment, setFirstFiltersDepartment] = useState(null);
  const [firstFiltersLocation, setFirstFiltersLocation] = useState(null);
  const [filtersInitialDepartment, setFiltersInitialDepartment] =
    useState({});
  const [filtersLocation, setFiltersLocation] = useState(null);
  const [filtersInitialLocation, setFiltersInitialLocation] =
    useState({});
  const [isFiltered, setIsFiltered] = useState({
    locations: false,
    department: false,
  });
  return (
    <Context.Provider
      value={{
        setFiltersLocation,
        filtersLocation,
        setFiltersDepartment,
        filtersDepartment,
        isFiltered,
        setIsFiltered,
        filteredData,
        setFilteredData,
        positionsCardData: jobOpportunitiesData,
        setFiltersInitialLocation,
        filtersInitialLocation,
        setJobOpportunitiesData,
        setFiltersInitialDepartment,
        filtersInitialDepartment,
        firstFiltersLocation,
        firstFiltersDepartment,
        isOpenGlobal,
        setIsOpenGlobal,
        platform,
        platformVideo
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
export { FilterContextProvider };
