import MainTemplate from "../../templates/Main";
import AboutPageTemplate from "./AboutPageTemplate";
import {Helmet} from "react-helmet";

const AboutPage = () => {
  return (
      <>
        <Helmet>
          <title>Giti2c | About us</title>
        </Helmet>
        <MainTemplate>
            <AboutPageTemplate />
        </MainTemplate>
      </>
  );
};

export default AboutPage;
