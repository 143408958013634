import JobOpportunitiesCard from "./JobOpportunitiesCard";
import {useEffect} from "preact/compat";
import {useState} from "preact/hooks";
import {Helmet} from "react-helmet";

const JobOpportunitiesInfoContainer = ({ id }) => {
  const [cardData, setCardData] = useState(null)
    const requestOptions = {
        method: 'GET'
    };
  useEffect(() => {
      fetch(`/share/vacancies/${id}`, requestOptions).then((response) => {
          if (response.ok) {
              return response.json();
          }
          throw new Error('Something went wrong');
      }).then((responseJson) => {
          setCardData(responseJson)
        }).catch((error) => {
              console.log(error)
          });
  }, [])

    let cardDataModify = null;
    if(cardData) {
          cardDataModify = {...cardData, countries: cardData.locations}
      }
  return (
      <>
      <Helmet>
          <title>{cardDataModify?.title}</title>
          <meta property="og:title" content={cardDataModify?.title} />
          <meta property="og:type" content="website" />
      </Helmet>
    <div>
      <JobOpportunitiesCard {...cardDataModify} />
    </div>
      </>
  );
};

export default JobOpportunitiesInfoContainer;
