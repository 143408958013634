import Btn from "../btn";
import LocationIcon from "../../assets/location-icon.svg";
import DepartmentIcon from "../../assets/department-icon.svg";
import JobOpportunitiesDescription from "./JobOpportunitiesDescription";
import { useShowModal } from "../../modules/modals/hooks";
import { applyPositionModal } from "../../modules/modals/modals";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "preact/hooks";
import ModalsContext from "../../modules/user/components/ModalsContext";
import FilterContext from "../../modules/filters/components/FilterContext";
import classNames from "classnames";

const JobOpportunitiesCard = ({
  title,
  text_full,
  slug,
  department,
  countries,
}) => {
  const showApplyPositionModal = useShowModal(
    applyPositionModal({
      title: title,
      department: department,
      slug: slug,
      location: countries,
      locationIcon: <LocationIcon />,
      departmentIcon: <DepartmentIcon />,
      className: "filters",
    })
  );
  const { show } = useContext(ModalsContext);
  const { platform } = useContext(FilterContext);
  const [isFilled, setIsFilled] = useState(false);
  useEffect(() => {
    if (!show) {
      setIsFilled(false);
    }
  }, [show]);
  const showModal = () => {
    showApplyPositionModal();
    setIsFilled(true);
  };
  const navigate = useNavigate();
  return (
    <div className={"job-opportunities-info"}>
      {!title ? null : <>
        <div className={classNames(`job-opportunities-info__head`, `job-opportunities-info__head-${platform}`)}>
          <div className={"job-opportunities-info__head-left"}>
            <div
                onClick={() => navigate("/job-opportunities")}
                className={"job-opportunities-info__head-crumb"} />
            <div className={"job-opportunities-info__head-crumb-mobile"}>
            <span
                onClick={() => navigate("/job-opportunities")}
                className={"job-opportunities-info__head-crumb-mobile-back"}
            >
              Back to Job opportunities /
            </span>
              <span className={"job-opportunities-info__head-crumb-mobile-name"}>
              {" "}
                {title}
            </span>
            </div>
            <div className={"job-opportunities-info__head-title"}>
              {title}
            </div>
            <div className={"job-opportunities-info__head-filters"}>
              <div className={"job-opportunities-info__head-filters-item"}>
                <div
                    className={"job-opportunities-info__head-filters-item-image"}
                >
                  <DepartmentIcon />
                </div>
                <div>{department}</div>
              </div>
              <div className={"job-opportunities-info__head-filters-item"}>
                <div
                    className={"job-opportunities-info__head-filters-item-image"}
                >
                  <LocationIcon />
                </div>
                <div>{countries?.join(' / ')}</div>
              </div>
            </div>
          </div>
          <div className={"job-opportunities-info__btn"}>
            <Btn
                onClick={showModal}
                type="secondary"
                label={"Apply"}
                isFilled={isFilled}
            />
          </div>
        </div>
        <JobOpportunitiesDescription
            info={text_full}
        />
        <div className={"job-opportunities-info__btn-mobile"}>
          <Btn
              onClick={showModal}
              type="secondary"
              label={"Apply"}
              isFilled={isFilled}
          />
        </div>
      </>}
    </div>
  );
};

export default JobOpportunitiesCard;
