import { APP_ROUTES } from "./constants";
import AboutPage from "../../pages/about";
import IntroducingPage from "../../pages/introducing";
import JobOpportunitiesPage from "../../pages/jobOpportunities";
import PageNotFound from "../../pages/errorPages/404";
import JobOpportunitiesInfoPage from "../../pages/jobOpportunitiesInfo";
import { Route, Routes } from "react-router-dom";
import HomePage from "../../pages/home";

export default (
    <Routes>
      <Route path={APP_ROUTES.homePage} element={<HomePage />} />
      <Route path={APP_ROUTES.aboutPage} element={<AboutPage />} />
      <Route path={APP_ROUTES.introducingPage} element={<IntroducingPage />} />
      <Route
      path={APP_ROUTES.jobOpportunitiesInfo}
      element={<JobOpportunitiesInfoPage />}
    />
      <Route
      path={APP_ROUTES.jobOpportunitiesPage}
      element={<JobOpportunitiesPage />}
    />
      <Route element={<PageNotFound />} />
  </Routes>
);
